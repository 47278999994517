// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
    width: 100px;
}

.message {
    color: blue;
}

.message:hover {
    background-color: yellow;
}
`, "",{"version":3,"sources":["webpack://./client/src/pages/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".logo {\n    width: 100px;\n}\n\n.message {\n    color: blue;\n}\n\n.message:hover {\n    background-color: yellow;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
